export interface ValuationModel {
  _id?: string;
  email: string;
  phone: string;
  address: string;
  latitude: string;
  longitude: string;
  category_id: string;
  type: string;
  direction: { balcony: string; door: string };
  detail: {
    bedroom?: string;
    floor?: string;
    acreage?: string;
    width?: string;
    height?: string;
    facade?: string;
  };
}
export const ValuationDefault: ValuationModel = {
  email: "",
  phone: "",
  address: "",
  latitude: "",
  longitude: "",
  category_id: "",
  type: "used",
  direction: { balcony: "", door: "" },
  detail: {
    bedroom: "",
    floor: "",
    acreage: "",
    width: "",
    height: "",
    facade: "",
  },
};
